<template>
  <div class="cneter">
    <div style="display: flex;margin:15px;">
      <el-input style="width: 250px;margin-right: 15px;" v-model="prefix" placeholder="输入编号开头字母，如A"/>
      <el-input style="width: 250px;margin-right: 15px;" v-model="count" placeholder="输入生成数量，最多不能超过1000"/>
      <el-button type="primary"
                 :disabled="disabled"
                 v-loading.fullscreen.lock="fullscreenLoading"
                 @click="getErList">生成二维码</el-button>
      <el-button @click="printOut" :disabled="disabled">导出图片</el-button>
    </div>
    <div id="maina" class="main"
         :style="'height:'+ ermList/5*530+'px;'"
    >
      <div :id="'showEr'+index" class="showEr" v-for="(item,index) in ermList" :key="index">
        <img class="bjImage" src="../assets/12.png" style="width: 100%;height: 100%;">
        <img class="imgQr" :src="'data:image/jpg;base64,'+item.qrSrc" style="width: 231px;height: 231px;z-index: 999;">
        <!--  <img class="imgQr" src="../assets/ewm.jpg" style="width: 231px;height: 231px;z-index: 999;">-->
        <p>{{ item.treeId }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2canvas from 'html2canvas'
export default {
  name: "test",
  data(){
    return{
      ermList:[],
      count:"",
      prefix:'',
      fullscreenLoading:false,
      disabled:false
    }
  },
  mounted() {
    // this.getErList()
  },
  methods:{
    getErList(){
      this.fullscreenLoading = true
      if(this.prefix!=="" && this.count!==""){
        this.$axios.get('/plague/api/qrCode/create',{ // 除治
          params:{
            count:this.count,
            prefix:this.prefix,
            type:'elimination'
          }
        }).then(res=>{
          console.log(res.data);
          this.ermList = res.data
          this.fullscreenLoading = false
          this.$message.success('二维码生成完毕')
        })
      }
    },
    //
    dataURLToBlob(dataurl) {//ie 图片转格式
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime})
    },
    async downloadResult(name,num) {
      let canvasID = document.getElementById('showEr'+num);
      let a = document.createElement('a');
      await html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png")
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    async printOut() {
      this.disabled=true
      for(let a in this.ermList){
        console.log(a);
        let name = this.ermList[a].treeId
        // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
        // $(window).scrollTop(0); // jQuery 的方法
        document.body.scrollTop = 0; // IE的
        document.documentElement.scrollTop = 0; // 其他
        await this.downloadResult(name,a)
      }
      console.log('end');
      this.disabled = false
    },

  }
}
</script>

<style scoped>
.cneter{
  width: 100%;
  height: 100%;
}
.main{
  width: 1800px;
  text-align: left;
}
.showEr{
  /*background: url("../assets/1.jpg") no-repeat;*/
  /*background-size: 100% 100%;*/
  width: 319px;
  height: 508px;
  margin: 15px;
  position: relative;
  float: left;
  border: 1px solid rgba(121, 116, 116, 0.34);
}
.imgQr{
  position: absolute;
  top: 182px;
  left: 44px;
}
p {
    position: absolute;
    color: #000000;
    font-size: 35px;
    font-weight: bold;
    top: 130px;
    left: calc(50% - 119px);
    background: #ffffff;
    width: 76%;
    text-align: center;
    border-radius: 5px;
    height: 44px;
    line-height: 44px;
}
.bjImage{
  position: absolute;
}
</style>
